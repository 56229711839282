import { Toast } from './swal'

const hsh = {
  notice: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning'
}

export default function showFlashes() {
  $('.flashes-container').data('flashes')?.forEach(([key, value]) => {
    Toast.fire({
      icon: hsh[key] || 'info',
      title: value
    })
  })
  $('.flashes-container').data('flashes', null)
}
