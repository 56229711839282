import Inputmask from 'inputmask'

export default function maskedInput() {
  const inputs = document.querySelectorAll('.js-masked-input')
  inputs.forEach((input) => {
    const mask = input.getAttribute('mask')
    if (!mask) return

    const options = {
      autoUnmask: false,
      placeholder: ' ',
      showMaskOnHover: false,
      showMaskOnFocus: false
    }

    const maskType = input.getAttribute('mask-type')
    if (maskType === null || maskType === 'mask') options.mask = mask
    else if (maskType === 'regex') options.regex = mask

    Inputmask(options).mask(input)
  })
}
