import '../stylesheets/white_label'

import 'jquery'
import 'popper.js'
import 'bootstrap'

import '../javascripts/init'

import '../javascripts/styxies/main_page'

import showFlashes from '../javascripts/common/flashes'

$(() => {
  showFlashes()
})

const images = require.context('../images', true)

