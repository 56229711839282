/* eslint-disable camelcase */
window.Styxie.Initializers.MainPage = {
  show: () => {
    $.HSCore.components.HSShowAnimation.init('.js-animation-link', {
      afterShow() {
        $('.js-slick-carousel').slick('setPosition')
      }
    })

    $.HSCore.components.HSSlickCarousel.init('.js-slick-carousel')
    $.HSCore.components.HSFancyBox.init('.js-fancybox')
  },
  feature_review_reporting: () => {
    $.HSCore.components.HSFancyBox.init('.js-fancybox')
  },
  feature_review_analytics: () => {
    $.HSCore.components.HSSlickCarousel.init('.js-slick-carousel')
  },
  about: () => {
    $.HSCore.components.HSFancyBox.init('.js-fancybox')
  },
  pricing: () => {
    const locationPriceId = '#locationsPrice'
    const locationInputId = '#locationCountInput'
    const pricePerLocationId = '#locationsPricePerLocation'

    function findGraduatedLevel(itemNum, price) {
      let currentLevel = Math.min(...price.map((k) => {
        if (itemNum <= k.upTo) {
          return k.upTo
        }

        return null
      }).filter((n) => n))

      if (currentLevel === Infinity) {
        currentLevel = null
      }

      return currentLevel
    }

    function findCurrentGraduatedPrice(graduatedLevel, price) {
      const currentPrice = price.filter((obj) => obj.upTo === graduatedLevel)

      return currentPrice[0].amount
    }

    function calcGraduatedCost(amount, monthly) {
      const monthlyPrice = [
        { upTo: 1, amount: 25 },
        { upTo: 9, amount: 15 },
        { upTo: 24, amount: 13.5 },
        { upTo: 49, amount: 12.75 },
        { upTo: null, amount: 12 }
      ]
      const yearlyPrice = [
        { upTo: 1, amount: 285 },
        { upTo: 9, amount: 171 },
        { upTo: 24, amount: 153.9 },
        { upTo: 49, amount: 145.35 },
        { upTo: null, amount: 136.8 }
      ]
      const currentPrice = monthly === true ? monthlyPrice : yearlyPrice

      const totalPrice = Array.from({ length: amount }, (_, i) => i + 1).map((itemNum) => {
        const graduatedLevel = findGraduatedLevel(itemNum, currentPrice)
        const graduatedPrice = findCurrentGraduatedPrice(graduatedLevel, currentPrice)

        return graduatedPrice
      }).reduce((a, b) => a + b)

      return totalPrice
    }

    function updateLocationPrice(val, priceCssId, perLocationPriceId, monthly) {
      const amount = parseInt(val, 10)
      const cost = calcGraduatedCost(amount, monthly)
      const perLocationCost = cost / amount
      $(priceCssId).text((cost).toFixed(2))
      $(perLocationPriceId).text((perLocationCost).toFixed(2))

      if (monthly === true) {
        $('.js-price-yearly').addClass('d-none')
        $('.js-price-monthly').removeClass('d-none')
      } else {
        $('.js-price-yearly').removeClass('d-none')
        $('.js-price-monthly').addClass('d-none')
      }
    }

    $('.js-animation-link').on('click', (e) => {
      $('.js-animation-link').removeClass('active')

      const $self = $(e.currentTarget)
      $self.addClass('active')
      $(locationInputId).data('period', $self.data('period'))

      updateLocationPrice($(locationInputId).val(), locationPriceId, pricePerLocationId, $self.data('period') === 'monthly')
    })

    $(locationInputId).on('input', (e) => {
      const $self = $(e.currentTarget)
      const selected_val = parseInt($self.val(), 10)

      if (selected_val === 0) {
        $self.val(1)
      }

      $('#locationCountText').html($self.val())
      updateLocationPrice($self.val(), locationPriceId, pricePerLocationId, $self.data('period') === 'monthly')
    })

    $('#sign-up').on('click', (evt) => {
      evt.preventDefault()

      const selectedLocationCount = $(locationInputId).val()
      const url = `/sign_up?total_locations_count=${selectedLocationCount}&plan_period=${$(locationInputId).data('period')}`

      window.location.assign(url)
    })

    $.HSCore.components.HSSlickCarousel.init('.js-slick-carousel')
  }
}
/* eslint-enable camelcase */
