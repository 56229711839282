import Swal from 'sweetalert2'

export const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

const DEFAULT_SWAL_OPTIONS = {
  title: 'Are you sure?',
  text: 'Once deleted, you will not be able to recover this!',
  icon: 'warning',
  showCancelButton: true,
  customClass: {
    popup: 'swal-custom',
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn'
  },
  onBeforeOpen(el) {
    $(el).find('button.swal2-styled').toggleClass('swal2-confirm swal2-cancel swal2-styled')
    if ($(el).find('.swal2-html-container').text().length <= 55) { // 55 is approximate length of symbols
      $(el).find('.swal2-html-container').toggleClass('text-center')
    }
  }
}
/* eslint-disable no-unused-expressions */

export function confirmWithSwal({ submit, reject, ...options }) {
  return Swal.fire({
    ...DEFAULT_SWAL_OPTIONS,
    ...options
  }).then((res) => {
    if (res.isConfirmed) {
      typeof submit === 'function' && submit(res.value)
    } else {
      typeof reject === 'function' && reject()
    }
  })
}
/* eslint-enable no-unused-expressions */
